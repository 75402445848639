const TEMPERATURE = 'temperature'
const HUMIDITY = 'humidity'
const CO2 = 'co2'
const FORMALDEHYDE = 'formaldehyde'
const PRESSURE = 'pressure'
const VOCS = 'vocs'
const VOCS_INDEX = 'vocsIndex'
const PM1 = 'pm1'
const PM4 = 'pm4'
const PM10 = 'pm10'
const PM25 = 'pm25'
const CO = 'co'
const O3 = 'o3'
const RADON = 'radon'
const VENTILATION_MODE = 'ventilationMode'
const TEMPERATURE_CONTROL = 'temperatureControl'
const COVID_19 = 'covid19'
const IAQ = 'iaq'
const VENTILATION_INDICATOR = 'ventilationIndicator'
const THERMAL_INDICATOR = 'thermalIndicator'
const RADIANT_TEMPERATURE = 'radiantTemperature'
const LIGHT = 'lux'
const NOISE = 'dB'
const NO2 = 'no2'
const NOX = 'nox'

const WEATHER = 'weather'

export default {
  TEMPERATURE,
  HUMIDITY,
  CO2,
  FORMALDEHYDE,
  PRESSURE,
  VOCS,
  VOCS_INDEX,
  PM1,
  PM4,
  PM10,
  PM25,
  CO,
  O3,
  RADON,
  VENTILATION_MODE,
  TEMPERATURE_CONTROL,
  COVID_19,
  IAQ,
  VENTILATION_INDICATOR,
  THERMAL_INDICATOR,
  RADIANT_TEMPERATURE,
  LIGHT,
  NOISE,
  NO2,
  NOX,

  WEATHER
}
