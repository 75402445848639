import { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

// Material-UI imports
import { Modal } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import NewIndicator from '../../../../common/components/Indicators/NewIndicators/NewIndicators'

// Imports from own project
import styles from './TypeInfoModalContainer.module.scss'
import { CloseIcon, Ranges, EngRanges } from 'icons'
import { browserHistory } from 'react-router'
import * as Routes from 'constants/Routes'
import DataTypes from 'constants/DataTypes'

const ConfirmationModal = props => {
  const { hideModal, translate, type, vocsType, activeLanguage, numericValue } = props

  const handleMoreInformationClick = type => {
    hideModal()
    switch (type) {
      case DataTypes.IAQ:
        return browserHistory.push({
          pathname: Routes.MORE_INFORMATION,
          state: { type: 'iaq' }
        })
      case DataTypes.VENTILATION_INDICATOR:
        return browserHistory.push({
          pathname: Routes.MORE_INFORMATION,
          state: { type: 'ventilationIndicator' }
        })
      case DataTypes.COVID_19:
        return browserHistory.push({
          pathname: Routes.MORE_INFORMATION,
          state: { type: 'covid19' }
        })
      case DataTypes.THERMAL_INDICATOR:
        return browserHistory.push({
          pathname: Routes.MORE_INFORMATION,
          state: { type: 'thermalIndicator' }
        })
    }
  }

  const [open] = useState(true)

  const getTitle = type => {
    switch (type) {
      case DataTypes.VENTILATION_INDICATOR:
        return translate('ventilationIndicator')
      case DataTypes.COVID_19:
        return translate('ViricoIndicator')
      case DataTypes.THERMAL_INDICATOR:
        return translate('indicatorThermico')
      case DataTypes.IAQ:
        return translate('iaqInterior')
      default:
        break
    }
  }

  const getContent = type => {
    switch (type) {
      case DataTypes.VENTILATION_INDICATOR:
        if (vocsType.length > 0) {
          return (
            <Translate
              id='ventilationInfo'
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id='ventilationInfoNoVocs'
              options={{ renderInnerHtml: true }}
            />
          )
        }
      case DataTypes.COVID_19:
        return (
          <Translate
            id='micaLiteInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case DataTypes.IAQ:
        return (
          <Translate
            id='micaInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case DataTypes.THERMAL_INDICATOR:
        return (
          <Translate
            id='micaMiniInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      default:
        break
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <div className={styles.modalContainer}>
            <div className={styles.closeButtonPosition}>
              <button
                className={styles.closeModalArrow}
                onClick={hideModal}
              >
                <img
                  src={CloseIcon}
                  alt='close'
                  className={styles.iconButton}
                />
              </button>
            </div>

            <Typography className={styles.header}>{getTitle(type)}</Typography>
            {numericValue !== undefined && (
              <div className={styles.indicatorWrapper}>
                <NewIndicator numericValue={numericValue} />
              </div>
            )}
            <div className={styles.modal}>
              {getContent(type)}
              <div className={styles.contentImage}>
                <img
                  src={activeLanguage.code === 'es' ? Ranges : EngRanges}
                  alt='Images'
                  className={styles.images}
                />
              </div>
            </div>
            <div className={styles.infoButton}>
              <a
                onClick={() => handleMoreInformationClick(type)}
                target='_blank'
                className={styles.informationButton}
                rel='noreferrer'
              >
                {translate('learnMore')}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

ConfirmationModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default ConfirmationModal
